<template>
  <span>
    <v-card>
      <v-card-title primary-title>
        Códigos Identificadores não Importados por Tema
      </v-card-title>
      <v-card-text>
        <v-row class="mx-auto" justify="space-around">
          <v-col
            v-for="(valor, categoria) in valores"
            :key="categoria"
            cols="auto"
          >
            <v-card>
              <v-card-subtitle class="caption">
                {{
                  temas_validos.find((tema) => tema.slug === categoria)
                    .category || categoria
                }}
              </v-card-subtitle>
              <v-card-text class="display-3">
                {{ valor.length }}
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row class="mt-4 mx-auto" justify="space-between">
          <v-col cols="12" sm="6" md="3">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              class=""
              label="Pesquisar"
              hide-details
              single-line
            />
          </v-col>
          <v-col cols="auto">
            <v-btn
              id="btn-exportar-xlsx"
              color="primary"
              dark
              elevation="1"
              relative
              text
              medium
              :loading="downloadingArquivoIdentificadores"
              @click="downloadCodigosIdentificadoresNaoImportados()"
            >
              <v-icon left size="30px">
                mdi-microsoft-excel
              </v-icon>
              Exportar XLSX
            </v-btn>
          </v-col>
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :items="items"
              :search.sync="search"
              :headerProps="{ sortByText: 'Ordenar Por' }"
              :footerProps="{ itemsPerPageOptions: [10, 50, 100, -1] }"
              no-data-text="Nenhum registro disponível"
              no-results-text="Nenhum registro correspondente encontrado"
              :mobile-breakpoint="10"
            >
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </span>
</template>

<script>
  import ManutencaoTecnicosService from "../../services/ManutencaoTecnicosService";

  export default {
    name: "CardQtdIdentificadoresNaoImportados",
    props: {
      base: {
        type: String,
        required: true,
      },
      competencia_de: {
        type: String,
        required: true
      },
      competencia_ate: {
        type: String,
        required: true
      },
      temas_validos: {
        type: Array,
        required: true,
      },
      temas_selecionados: {
        type: Array,
        required: true,
      },
      valores: {
        type: Object,
        required: true,
      },
    },
    data: () => ({
      search: "",
      downloadingArquivoIdentificadores: false,
    }),
    computed: {
      headers() {
        let headers = [];

        this.temas_selecionados.forEach((tema_selecionado) => {
          headers.push({
            text: this.temas_validos.find(
              (tema_valido) => tema_valido.slug === tema_selecionado
            ).category,
            value: tema_selecionado.toLowerCase(),
          });
        });

        return headers;
      },
      items() {
        // Cria um conjunto de todos os códigos de todas as listas nos temas selecionados
        const uniqueCodes = new Set();
        this.temas_selecionados.forEach((tema) => {
          if (this.valores[tema]) {
            this.valores[tema].forEach((code) => uniqueCodes.add(code));
          }
        });

        // Transforma o conjunto em um array de objetos para a data-table
        const result = Array.from(uniqueCodes).map((code) => {
          const obj = {};
          this.temas_selecionados.forEach((tema) => {
            obj[tema.toLowerCase()] =
              this.valores[tema] && this.valores[tema].includes(code)
                ? code
                : null;
          });
          return obj;
        });

        return result;
      },
    },
    methods: {
      downloadCodigosIdentificadoresNaoImportados() {
        this.downloadingArquivoIdentificadores = true;

        this.$toast.info(
          "Preparando seu arquivo para download, por favor, aguarde",
          "",
          {
            position: "topRight",
            timeout: 5000,
          }
        );

        ManutencaoTecnicosService.downloadCodigosIdentificadoresNaoImportados(
          this.competencia_de,
          this.competencia_ate,
          this.temas_selecionados.join(","),
          this.base,
          { timeout: 60 * 60 * 1000 }
        )
          .then(async (res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement("a");
            link.href = url;

            link.setAttribute(
              "download",
              `codigos_identificadores_nao_importados-${this.base}.xlsx`
            );
            document.body.appendChild(link);
            link.click();
          })
          .catch((err) => {
            this.$toast.error("Erro no download do arquivo", "", {
              position: "topRight",
              timeout: 5000,
            });
            throw err;
          })
          .finally(() => (this.downloadingArquivoIdentificadores = false));
      },
    },
  };
</script>

<style></style>
